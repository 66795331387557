import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Link,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { useLocation } from "react-router";
import Socials from "./common/Socials";
import "./styles.css";
import { Link as LinkRoute } from "react-router-dom";

import {
  FaFilePdf,
} from "react-icons/fa";

interface NavLinkProps {
  link: string;
  children?: ReactNode;
  isRoute?: boolean;
}

const Links = [
  { label: "Home", href: "/", isRoute: true },
  { label: "Projects", href: "/projects", isRoute: true },
  // {
  //   label: "Resume",
  //   isRoute: false,
  //   href: "https://docs.google.com/document/d/1vlsLjMXJXxSN5C8whShVwORdA1WdVFqQCmkIXOozkpk/edit",
  // },
];

const NavLink = ({ link, children, isRoute }: NavLinkProps) =>
  isRoute ? (
    <LinkRoute to={link}>{children}</LinkRoute>
  ) : (
    <Link
      href={link}
      target="_blank"
      _hover={{
        cursor: "pointer",
      }}
    >
      {children}
    </Link>
  );

export default function Header() {
  const pathname = useLocation().pathname;

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box
      bg={useColorModeValue("gray.100", "gray.900")}
      className="header"
      zIndex={999}
    >
      <Box maxWidth="1440px" margin="0 auto" px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
            bg="#f9004d"
            _hover={{ backgroundColor: "#f9004d" }}
          />
          <HStack spacing={8} alignItems={"center"} ml="20px">
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
            >
              {Links.map((link, index) =>
                link.isRoute ? (
                  <LinkRoute
                    className={`${pathname === link.href ? "active-link" : ""
                      } link-header`}
                    to={link.href}
                    key={index}
                  >
                    {link.label}
                  </LinkRoute>
                ) : (
                  <Link
                    key={index}
                    className={`${pathname === link.href ? "active-link" : ""
                      } link-header`}
                    href={link.href}
                    target="_blank"
                    _hover={{
                      cursor: "pointer",
                    }}
                  >
                    {link.label}
                  </Link>
                )
              )}
              <Link href="/assets/resume/dc-resume-open.pdf" target="_blank">
                <HStack>
                  <FaFilePdf />
                  <Text>
                    Resume
                  </Text>
                </HStack>
              </Link>
            </HStack>
          </HStack>
          <Spacer />
          <HStack as={"nav"} spacing={4} mr={{ base: "10px", md: "20px" }}>
            <Socials />
          </HStack>
        </Flex>
        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {Links.map((link) => (
                <NavLink
                  link={link.href}
                  key={link.href}
                  isRoute={link.isRoute}
                >
                  {link.label}
                </NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
